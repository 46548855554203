import React from 'react';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { icons } from './assets/icons';
import { Apps } from './configs';
import './polyfill';
import "./scss/coreui/scss/coreui.scss";
import * as serviceWorker from './serviceWorker';

React.icons = icons

ReactDOM.render(
   Apps,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
