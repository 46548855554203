import {unitOfMeasurementConstants} from '@ams-fe/action-module'

const {
    SAVE_UOM_ERROR,
    SAVE_UOM_SUCCESS,
    SAVE_UOM_START,
    DELETE_UOM_ERROR,
    DELETE_UOM_SUCCESS,
    DELETE_UOM_START,
    PREVIEW_UOM_ERROR,
    PREVIEW_UOM_SUCCESS,
    PREVIEW_UOM_START,
    EDIT_UOM_ERROR,
    EDIT_UOM_SUCCESS,
    EDIT_UOM_START,
    SEARCH_UOM_ERROR,
    SEARCH_UOM_SUCCESS,
    SEARCH_UOM_START,
    FETCH_UOM_TYPE_START,
    FETCH_UOM_TYPE_SUCCESS,
    FETCH_UOM_TYPE_ERROR,
    FETCH_UOM_MIN_ERROR,
    FETCH_UOM_MIN_SUCCESS,
    FETCH_UOM_MIN_START
} = unitOfMeasurementConstants

const saveInitialState = {
    isSaveLoading: false,
    uomSaveSuccessMessage: '',
    uomSaveErrorMessage: ''
}
const searchInitialState={
    isSearchLoading:false,
    uomList:[],
    uomSearchErrorMessage:''
}
const previewInitialState={
    isPreviewLoading:false,
    previewGroupData:'',
    previewGroupErrorLoading:''
}
const editInitialState={
    isEditLoading:false,
    editGroupSuccessMessage:'',
    editGroupErrorMessage:''
}
const deleteInitialState={
    isDeleteLoading:false,
    deleteGroupSuccessMessage:'',
    deleteLegerErrorMessage:''
}
const typeInitialState={
    isTypeLoading:false,
    typeList:[],
    typeErrorMessage:''
}
const minInitialState= {
    isMinLoading:false,
    minList:[],
    minErrorMessage:''
}

export const saveUomReducer = (state = {saveInitialState}, action) => {
    switch (action.type) {
        case SAVE_UOM_START:
            return {
                ...state,
                isSaveLoading: true,
                uomSaveErrorMessage: '',
                uomSaveSuccessMessage: ''
            }
        case SAVE_UOM_SUCCESS:
            return {
                ...state,
                isSaveLoading: false,
                uomSaveSuccessMessage: action.payload.data || 'Uom Added Successfully !!!',
                uomSaveErrorMessage: ''
            }
        case SAVE_UOM_ERROR:
            return {
                ...state,
                isSaveLoading: false,
                uomSaveErrorMessage: action.payload.data,
                uomSaveSuccessMessage: ''
            }
        default:
            return {
                ...state
            }
    }

}
export const editUomReducer = (state = {editInitialState}, action) => {
    switch (action.type) {
        case EDIT_UOM_START:
            return {
                ...state,
                isEditLoading: true,
                editUomSuccessMessage: '',
                editUomErrorMessage: ''
            }
        case EDIT_UOM_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                editUomSuccessMessage: action.payload.data,
                editUomErrorMessage: ''
            }
        case EDIT_UOM_ERROR:
            return {
                ...state,
                isEditLoading: false,
                editUomSuccessMessage: '',
                editUomErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const searchUomReducer = (state = {searchInitialState}, action) => {
    switch (action.type) {
        case SEARCH_UOM_START:
            return {
                ...state,
                isSearchLoading:true,
                uomList:[],
                uomSearchErrorMessage:''
            }
        case SEARCH_UOM_SUCCESS:
            return {
                ...state,
                isSearchLoading:false,
                uomList:action.payload.data,
                uomSearchErrorMessage:''
            }
        case SEARCH_UOM_ERROR:
            return {
                ...state,
                isSearchLoading:false,
                uomList:[],
                uomSearchErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const previewUomReducer = (state = {previewInitialState}, action) => {
    switch (action.type) {
        case PREVIEW_UOM_START:
            return {
                ...state,
                isPreviewLoading:true,
                previewUomData:'',
                previewUomErrorLoading:''
            }
        case PREVIEW_UOM_SUCCESS:
            return {
                ...state,
                isPreviewLoading:false,
                previewUomData:action.payload.data,
                previewUomErrorLoading:''
            }
        case PREVIEW_UOM_ERROR:
            return {
                ...state,
                isPreviewLoading:false,
                previewUomData:'',
                previewUomErrorLoading:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const deleteUomReducer = (state = {deleteInitialState}, action) => {
    switch (action.type) {
        case DELETE_UOM_START:
            return {
                ...state,
                isDeleteLoading:true,
                deleteUomSuccessMessage:'',
                deleteLegerErrorMessage:''
            }
        case DELETE_UOM_SUCCESS:
            return {
                ...state,
                isDeleteLoading:false,
                deleteUomSuccessMessage:action.payload.data,
                deleteLegerErrorMessage:''
            }
        case DELETE_UOM_ERROR:
            return {
                ...state,
                isDeleteLoading:false,
                deleteUomSuccessMessage:'',
                deleteUomErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}

export const typeUomReducer = (state = {typeInitialState}, action) => {
    switch (action.type) {
        case FETCH_UOM_TYPE_START:
            return {
                ...state,
                isTypeLoading:true,
                typeList:[],
                typeErrorMessage:''
            }
        case FETCH_UOM_TYPE_SUCCESS:
            return {
                ...state,
                isTypeLoading:false,
                typeList:action.payload.data,
                typeErrorMessage:''
            }
        case FETCH_UOM_TYPE_ERROR:
            return {
                ...state,
                isTypeLoading:false,
                typeList:[],
                typeErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}

export const minUomReducer = (state = {minInitialState}, action) => {
    switch (action.type) {
        case FETCH_UOM_MIN_START:
            return {
                ...state,
                isMinLoading:true,
                minList:[],
                minErrorMessage:''
            }
        case FETCH_UOM_MIN_SUCCESS:
            return {
                ...state,
                isMinLoading:false,
                minList:action.payload.data,
                minErrorMessage:''
            }
        case FETCH_UOM_MIN_ERROR:
            return {
                ...state,
                isMinLoading:false,
                minList:[],
                minErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}

