import {activityConstants} from '@ams-fe/action-module'

const {
    SAVE_CONTRA_ERROR,
    SAVE_CONTRA_SUCCESS,
    SAVE_CONTRA_START,
    DELETE_CONTRA_ERROR,
    DELETE_CONTRA_SUCCESS,
    DELETE_CONTRA_START,
    PREVIEW_CONTRA_ERROR,
    PREVIEW_CONTRA_SUCCESS,
    PREVIEW_CONTRA_START,
    EDIT_CONTRA_ERROR,
    EDIT_CONTRA_SUCCESS,
    EDIT_CONTRA_START,
    SEARCH_ACTIVITY_ERROR,
    SEARCH_ACTIVITY_START,
    SEARCH_ACTIVITY_SUCCESS
} = activityConstants

const saveInitialState = {
    isSaveLoading: false,
    contraSaveSuccessMessage: '',
    contraSaveErrorMessage: ''
}
const searchInitialState={
    isSearchLoading:false,
    activityList:[],
    contraSearchErrorMessage:''
}
const previewInitialState={
    isPreviewLoading:false,
    previewGroupData:'',
    previewGroupErrorLoading:''
}
const editInitialState={
    isEditLoading:false,
    editGroupSuccessMessage:'',
    editGroupErrorMessage:''
}
const deleteInitialState={
    isDeleteLoading:false,
    deleteGroupSuccessMessage:'',
    deleteLegerErrorMessage:''
}

export const saveContraReducer = (state = {saveInitialState}, action) => {
    switch (action.type) {
        case SAVE_CONTRA_START:
            return {
                ...state,
                isSaveLoading: true,
                contraSaveErrorMessage: '',
                contraSaveSuccessMessage: ''
            }
        case SAVE_CONTRA_SUCCESS:
            return {
                ...state,
                isSaveLoading: false,
                contraSaveSuccessMessage: action.payload.data || 'Contra Added Successfully !!!',
                contraSaveErrorMessage: ''
            }
        case SAVE_CONTRA_ERROR:
            return {
                ...state,
                isSaveLoading: false,
                contraSaveErrorMessage: action.payload.data,
                contraSaveSuccessMessage: ''
            }
        default:
            return {
                ...state
            }
    }

}
export const editContraReducer = (state = {editInitialState}, action) => {
    switch (action.type) {
        case EDIT_CONTRA_START:
            return {
                ...state,
                isEditLoading: true,
                editContraSuccessMessage: '',
                editContraErrorMessage: ''
            }
        case EDIT_CONTRA_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                editContraSuccessMessage: action.payload.data,
                editContraErrorMessage: ''
            }
        case EDIT_CONTRA_ERROR:
            return {
                ...state,
                isEditLoading: false,
                editContraSuccessMessage: '',
                editContraErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const searchActivityReducer = (state = {searchInitialState}, action) => {
    switch (action.type) {
        case SEARCH_ACTIVITY_START:
            return {
                ...state,
                isSearchLoading:true,
                activityList:[],
                contraSearchErrorMessage:''
            }
        case SEARCH_ACTIVITY_SUCCESS:
            return {
                ...state,
                isSearchLoading:false,
                activityList:action.payload.data,
                contraSearchErrorMessage:''
            }
        case SEARCH_ACTIVITY_ERROR:
            return {
                ...state,
                isSearchLoading:false,
                activityList:[],
                contraSearchErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const previewContraReducer = (state = {previewInitialState}, action) => {
    switch (action.type) {
        case PREVIEW_CONTRA_START:
            return {
                ...state,
                isPreviewLoading:true,
                previewContraData:'',
                previewContraErrorLoading:''
            }
        case PREVIEW_CONTRA_SUCCESS:
            return {
                ...state,
                isPreviewLoading:false,
                previewContraData:action.payload.data,
                previewContraErrorLoading:''
            }
        case PREVIEW_CONTRA_ERROR:
            return {
                ...state,
                isPreviewLoading:false,
                previewContraData:'',
                previewContraErrorLoading:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const deleteContraReducer = (state = {deleteInitialState}, action) => {
    switch (action.type) {
        case DELETE_CONTRA_START:
            return {
                ...state,
                isDeleteLoading:true,
                deleteContraSuccessMessage:'',
                deleteLegerErrorMessage:''
            }
        case DELETE_CONTRA_SUCCESS:
            return {
                ...state,
                isDeleteLoading:false,
                deleteContraSuccessMessage:action.payload.data,
                deleteLegerErrorMessage:''
            }
        case DELETE_CONTRA_ERROR:
            return {
                ...state,
                isDeleteLoading:false,
                deleteContraSuccessMessage:'',
                deleteLegerErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}