import {unitOfMeasurementConstants} from "./UnitOfMeasurementConstants";

const {
    SAVE_UOM_ERROR,
    SAVE_UOM_START,
    SAVE_UOM_SUCCESS,
    EDIT_UOM_ERROR,
    EDIT_UOM_START,
    EDIT_UOM_SUCCESS,
    PREVIEW_UOM_ERROR,
    PREVIEW_UOM_START,
    PREVIEW_UOM_SUCCESS,
    SEARCH_UOM_ERROR,
    SEARCH_UOM_START,
    SEARCH_UOM_SUCCESS,
    DELETE_UOM_ERROR,
    DELETE_UOM_START,
    DELETE_UOM_SUCCESS,
    FETCH_UOM_TYPE_ERROR,
    FETCH_UOM_TYPE_START,
    FETCH_UOM_TYPE_SUCCESS,
    FETCH_UOM_MIN_ERROR,
    FETCH_UOM_MIN_START,
    FETCH_UOM_MIN_SUCCESS

} = unitOfMeasurementConstants

export const saveUomStart = () => ({
    type: SAVE_UOM_START
})
export const saveUomSuccess = data => ({
    type: SAVE_UOM_SUCCESS,
    payload: {
        data: data
    }
})
export const saveUomError = (errorMessage) => ({
    type: SAVE_UOM_ERROR,
    payload: {
        data: errorMessage
    }
})
export const searchUomStart = () => ({
    type: SEARCH_UOM_START
})
export const searchUomSuccess = data => ({
    type: SEARCH_UOM_SUCCESS,
    payload: {
        data: data
    }
})
export const searchUomError = (errorMessage) => ({
    type: SEARCH_UOM_ERROR,
    payload: {
        data: errorMessage
    }
})
export const previewUomStart = () => ({
    type: PREVIEW_UOM_START
})
export const previewUomSuccess = data => ({
    type: PREVIEW_UOM_SUCCESS,
    payload: {
        data: data
    }
})
export const previewUomError = (errorMessage) => ({
    type: PREVIEW_UOM_ERROR,
    payload: {
        data: errorMessage
    }
})
export const editUomStart = () => ({
    type: EDIT_UOM_START
})
export const editUomSuccess = data => ({
    type: EDIT_UOM_SUCCESS,
    payload: {
        data: data
    }
})
export const editUomError = (errorMessage) => ({
    type: EDIT_UOM_ERROR,
    payload: {
        data: errorMessage
    }
})

export const deleteUomStart = () => ({
    type: DELETE_UOM_START
})
export const deleteUomSuccess = data => ({
    type: DELETE_UOM_SUCCESS,
    payload: {
        data: data
    }
})
export const deleteUomError = (errorMessage) => ({
    type: DELETE_UOM_ERROR,
    payload: {
        data: errorMessage
    }
})

export const fetchUomTypeStart = () => ({
    type: FETCH_UOM_TYPE_START
})
export const fetchUomTypeSuccess = data => ({
    type: FETCH_UOM_TYPE_SUCCESS,
    payload: {
        data: data
    }
})
export const fetchUomTypeError = (errorMessage) => ({
    type: FETCH_UOM_TYPE_ERROR,
    payload: {
        data: errorMessage
    }
})

export const fetchUomMinStart = () => ({
    type: FETCH_UOM_MIN_START
})
export const fetchUomMinSuccess = data => ({
    type: FETCH_UOM_MIN_SUCCESS,
    payload: {
        data: data
    }
})
export const fetchUomMinError = (errorMessage) => ({
    type: FETCH_UOM_MIN_ERROR,
    payload: {
        data: errorMessage
    }
})