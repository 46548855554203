import {stockItemConstants} from "./StockItemConstants";

const {
    SAVE_ITEM_ERROR,
    SAVE_ITEM_START,
    SAVE_ITEM_SUCCESS,
    EDIT_ITEM_ERROR,
    EDIT_ITEM_START,
    EDIT_ITEM_SUCCESS,
    PREVIEW_ITEM_ERROR,
    PREVIEW_ITEM_START,
    PREVIEW_ITEM_SUCCESS,
    SEARCH_ITEM_ERROR,
    SEARCH_ITEM_START,
    SEARCH_ITEM_SUCCESS,
    DELETE_ITEM_ERROR,
    DELETE_ITEM_START,
    DELETE_ITEM_SUCCESS,
    FETCH_ITEM_MIN_ERROR,
    FETCH_ITEM_MIN_START,
    FETCH_ITEM_MIN_SUCCESS,


    SAVE_STOCK_ITEM_ERROR,
    SAVE_STOCK_ITEM_START,
    SAVE_STOCK_ITEM_SUCCESS,
    EDIT_STOCK_ITEM_ERROR,
    EDIT_STOCK_ITEM_START,
    EDIT_STOCK_ITEM_SUCCESS,
    PREVIEW_STOCK_ITEM_ERROR,
    PREVIEW_STOCK_ITEM_START,
    PREVIEW_STOCK_ITEM_SUCCESS,
    SEARCH_STOCK_ITEM_ERROR,
    SEARCH_STOCK_ITEM_START,
    SEARCH_STOCK_ITEM_SUCCESS,
    DELETE_STOCK_ITEM_ERROR,
    DELETE_STOCK_ITEM_START,
    DELETE_STOCK_ITEM_SUCCESS,
    FETCH_STOCK_ITEM_MIN_ERROR,
    FETCH_STOCK_ITEM_MIN_START,
    FETCH_STOCK_ITEM_MIN_SUCCESS,
} = stockItemConstants

export const saveItemStart = () => ({
    type: SAVE_ITEM_START
})
export const saveItemSuccess = data => ({
    type: SAVE_ITEM_SUCCESS,
    payload: {
        data: data
    }
})
export const saveItemError = (errorMessage) => ({
    type: SAVE_ITEM_ERROR,
    payload: {
        data: errorMessage
    }
})
export const searchItemStart = () => ({
    type: SEARCH_ITEM_START
})
export const searchItemSuccess = data => ({
    type: SEARCH_ITEM_SUCCESS,
    payload: {
        data: data
    }
})
export const searchItemError = (errorMessage) => ({
    type: SEARCH_ITEM_ERROR,
    payload: {
        data: errorMessage
    }
})
export const previewItemStart = () => ({
    type: PREVIEW_ITEM_START
})
export const previewItemSuccess = data => ({
    type: PREVIEW_ITEM_SUCCESS,
    payload: {
        data: data
    }
})
export const previewItemError = (errorMessage) => ({
    type: PREVIEW_ITEM_ERROR,
    payload: {
        data: errorMessage
    }
})
export const editItemStart = () => ({
    type: EDIT_ITEM_START
})
export const editItemSuccess = data => ({
    type: EDIT_ITEM_SUCCESS,
    payload: {
        data: data
    }
})
export const editItemError = (errorMessage) => ({
    type: EDIT_ITEM_ERROR,
    payload: {
        data: errorMessage
    }
})

export const deleteItemStart = () => ({
    type: DELETE_ITEM_START
})
export const deleteItemSuccess = data => ({
    type: DELETE_ITEM_SUCCESS,
    payload: {
        data: data
    }
})
export const deleteItemError = (errorMessage) => ({
    type: DELETE_ITEM_ERROR,
    payload: {
        data: errorMessage
    }
})

export const fetchItemMinStart = () => ({
    type: FETCH_ITEM_MIN_START
})
export const fetchItemMinSuccess = data => ({
    type: FETCH_ITEM_MIN_SUCCESS,
    payload: {
        data: data
    }
})
export const fetchItemMinError = (errorMessage) => ({
    type: FETCH_ITEM_MIN_ERROR,
    payload: {
        data: errorMessage
    }
})





export const saveStockItemStart = () => ({
    type: SAVE_STOCK_ITEM_START
})
export const saveStockItemSuccess = data => ({
    type: SAVE_STOCK_ITEM_SUCCESS,
    payload: {
        data: data
    }
})
export const saveStockItemError = (errorMessage) => ({
    type: SAVE_STOCK_ITEM_ERROR,
    payload: {
        data: errorMessage
    }
})
export const searchStockItemStart = () => ({
    type: SEARCH_STOCK_ITEM_START
})
export const searchStockItemSuccess = data => ({
    type: SEARCH_STOCK_ITEM_SUCCESS,
    payload: {
        data: data
    }
})
export const searchStockItemError = (errorMessage) => ({
    type: SEARCH_STOCK_ITEM_ERROR,
    payload: {
        data: errorMessage
    }
})
export const previewStockItemStart = () => ({
    type: PREVIEW_STOCK_ITEM_START
})
export const previewStockItemSuccess = data => ({
    type: PREVIEW_STOCK_ITEM_SUCCESS,
    payload: {
        data: data
    }
})
export const previewStockItemError = (errorMessage) => ({
    type: PREVIEW_STOCK_ITEM_ERROR,
    payload: {
        data: errorMessage
    }
})
export const editStockItemStart = () => ({
    type: EDIT_STOCK_ITEM_START
})
export const editStockItemSuccess = data => ({
    type: EDIT_STOCK_ITEM_SUCCESS,
    payload: {
        data: data
    }
})
export const editStockItemError = (errorMessage) => ({
    type: EDIT_STOCK_ITEM_ERROR,
    payload: {
        data: errorMessage
    }
})

export const deleteStockItemStart = () => ({
    type: DELETE_STOCK_ITEM_START
})
export const deleteStockItemSuccess = data => ({
    type: DELETE_STOCK_ITEM_SUCCESS,
    payload: {
        data: data
    }
})
export const deleteStockItemError = (errorMessage) => ({
    type: DELETE_STOCK_ITEM_ERROR,
    payload: {
        data: errorMessage
    }
})

export const fetchStockItemMinStart = () => ({
    type: FETCH_STOCK_ITEM_MIN_START
})
export const fetchStockItemMinSuccess = data => ({
    type: FETCH_STOCK_ITEM_MIN_SUCCESS,
    payload: {
        data: data
    }
})
export const fetchStockItemMinError = (errorMessage) => ({
    type: FETCH_STOCK_ITEM_MIN_ERROR,
    payload: {
        data: errorMessage
    }
})


