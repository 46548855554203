import {paymentConstants} from '@ams-fe/action-module'

const {
    SAVE_PAYMENT_ERROR,
    SAVE_PAYMENT_SUCCESS,
    SAVE_PAYMENT_START,
    DELETE_PAYMENT_ERROR,
    DELETE_PAYMENT_SUCCESS,
    DELETE_PAYMENT_START,
    PREVIEW_PAYMENT_ERROR,
    PREVIEW_PAYMENT_SUCCESS,
    PREVIEW_PAYMENT_START,
    EDIT_PAYMENT_ERROR,
    EDIT_PAYMENT_SUCCESS,
    EDIT_PAYMENT_START,
    SEARCH_PAYMENT_ERROR,
    SEARCH_PAYMENT_SUCCESS,
    SEARCH_PAYMENT_START
} = paymentConstants

const saveInitialState = {
    isSaveLoading: false,
    paymentSaveSuccessMessage: '',
    paymentSaveErrorMessage: ''
}
const searchInitialState={
    isSearchLoading:false,
    paymentList:[],
    paymentSearchErrorMessage:''
}
const previewInitialState={
    isPreviewLoading:false,
    previewPaymentData:'',
    previewPaymentErrorLoading:''
}
const editInitialState={
    isEditLoading:false,
    editPaymentSuccessMessage:'',
    editPaymentErrorMessage:''
}
const deleteInitialState={
    isDeleteLoading:false,
    deletePaymentSuccessMessage:'',
    deleteLegerErrorMessage:''
}

export const savePaymentReducer = (state = {saveInitialState}, action) => {
    switch (action.type) {
        case SAVE_PAYMENT_START:
            return {
                ...state,
                isSaveLoading: true,
                paymentSaveErrorMessage: '',
                paymentSaveSuccessMessage: ''
            }
        case SAVE_PAYMENT_SUCCESS:
            return {
                ...state,
                isSaveLoading: false,
                paymentSaveSuccessMessage: action.payload.data || 'Payment Added Successfully !!!',
                paymentSaveErrorMessage: ''
            }
        case SAVE_PAYMENT_ERROR:
            return {
                ...state,
                isSaveLoading: false,
                paymentSaveErrorMessage: action.payload.data,
                paymentSaveSuccessMessage: ''
            }
        default:
            return {
                ...state
            }
    }

}
export const editPaymentReducer = (state = {editInitialState}, action) => {
    switch (action.type) {
        case EDIT_PAYMENT_START:
            return {
                ...state,
                isEditLoading: true,
                editPaymentSuccessMessage: '',
                editPaymentErrorMessage: ''
            }
        case EDIT_PAYMENT_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                editPaymentSuccessMessage: action.payload.data,
                editPaymentErrorMessage: ''
            }
        case EDIT_PAYMENT_ERROR:
            return {
                ...state,
                isEditLoading: false,
                editPaymentSuccessMessage: '',
                editPaymentErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const searchPaymentReducer = (state = {searchInitialState}, action) => {
    switch (action.type) {
        case SEARCH_PAYMENT_START:
            return {
                ...state,
                isSearchLoading:true,
                paymentList:[],
                paymentSearchErrorMessage:''
            }
        case SEARCH_PAYMENT_SUCCESS:
            return {
                ...state,
                isSearchLoading:false,
                paymentList:action.payload.data,
                paymentSearchErrorMessage:''
            }
        case SEARCH_PAYMENT_ERROR:
            return {
                ...state,
                isSearchLoading:false,
                paymentList:[],
                paymentSearchErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const previewPaymentReducer = (state = {previewInitialState}, action) => {
    switch (action.type) {
        case PREVIEW_PAYMENT_START:
            return {
                ...state,
                isPreviewLoading:true,
                previewPaymentData:'',
                previewPaymentErrorLoading:''
            }
        case PREVIEW_PAYMENT_SUCCESS:
            return {
                ...state,
                isPreviewLoading:false,
                previewPaymentData:action.payload.data,
                previewPaymentErrorLoading:''
            }
        case PREVIEW_PAYMENT_ERROR:
            return {
                ...state,
                isPreviewLoading:false,
                previewPaymentData:'',
                previewPaymentErrorLoading:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const deletePaymentReducer = (state = {deleteInitialState}, action) => {
    switch (action.type) {
        case DELETE_PAYMENT_START:
            return {
                ...state,
                isDeleteLoading:true,
                deletePaymentSuccessMessage:'',
                deleteLegerErrorMessage:''
            }
        case DELETE_PAYMENT_SUCCESS:
            return {
                ...state,
                isDeleteLoading:false,
                deletePaymentSuccessMessage:action.payload.data,
                deleteLegerErrorMessage:''
            }
        case DELETE_PAYMENT_ERROR:
            return {
                ...state,
                isDeleteLoading:false,
                deletePaymentSuccessMessage:'',
                deleteLegerErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}