export const stockItemConstants = {
    SAVE_ITEM_START: 'SAVE_ITEM_START',
    SAVE_ITEM_SUCCESS: 'SAVE_ITEM_SUCCESS',
    SAVE_ITEM_ERROR: 'SAVE_ITEM_ERROR',
    SEARCH_ITEM_START: 'SEARCH_ITEM_START',
    SEARCH_ITEM_SUCCESS: 'SEARCH_ITEM_SUCCESS',
    SEARCH_ITEM_ERROR: 'SEARCH_ITEM_ERROR',
    EDIT_ITEM_START: 'EDIT_ITEM_START',
    EDIT_ITEM_SUCCESS: 'EDIT_ITEM_SUCCESS',
    EDIT_ITEM_ERROR: 'EDIT_ITEM_ERROR',
    PREVIEW_ITEM_START: 'PREVIEW_ITEM_START',
    PREVIEW_ITEM_SUCCESS: 'PREVIEW_ITEM_SUCCESS',
    PREVIEW_ITEM_ERROR: 'PREVIEW_ITEM_ERROR',
    DELETE_ITEM_START:'DELETE_ITEM_START',
    DELETE_ITEM_SUCCESS:'DELETE_ITEM_SUCCESS',
    DELETE_ITEM_ERROR:'DELETE_ITEM_ERROR',
    FETCH_ITEM_MIN_START:'FETCH_ITEM_MIN_START',
    FETCH_ITEM_MIN_SUCCESS:'FETCH_ITEM_MIN_SUCCESS',
    FETCH_ITEM_MIN_ERROR:'FETCH_ITEM_MIN_ERROR',

    SAVE_STOCK_ITEM_START: 'SAVE_STOCK_ITEM_START',
    SAVE_STOCK_ITEM_SUCCESS: 'SAVE_STOCK_ITEM_SUCCESS',
    SAVE_STOCK_ITEM_ERROR: 'SAVE_STOCK_ITEM_ERROR',
    SEARCH_STOCK_ITEM_START: 'SEARCH_STOCK_ITEM_START',
    SEARCH_STOCK_ITEM_SUCCESS: 'SEARCH_STOCK_ITEM_SUCCESS',
    SEARCH_STOCK_ITEM_ERROR: 'SEARCH_STOCK_ITEM_ERROR',
    EDIT_STOCK_ITEM_START: 'EDIT_STOCK_ITEM_START',
    EDIT_STOCK_ITEM_SUCCESS: 'EDIT_STOCK_ITEM_SUCCESS',
    EDIT_STOCK_ITEM_ERROR: 'EDIT_STOCK_ITEM_ERROR',
    PREVIEW_STOCK_ITEM_START: 'PREVIEW_STOCK_ITEM_START',
    PREVIEW_STOCK_ITEM_SUCCESS: 'PREVIEW_STOCK_ITEM_SUCCESS',
    PREVIEW_STOCK_ITEM_ERROR: 'PREVIEW_STOCK_ITEM_ERROR',
    DELETE_STOCK_ITEM_START:'DELETE_STOCK_ITEM_START',
    DELETE_STOCK_ITEM_SUCCESS:'DELETE_STOCK_ITEM_SUCCESS',
    DELETE_STOCK_ITEM_ERROR:'DELETE_STOCK_ITEM_ERROR',
    FETCH_STOCK_ITEM_MIN_START:'FETCH_STOCK_ITEM_MIN_START',
    FETCH_STOCK_ITEM_MIN_SUCCESS:'FETCH_STOCK_ITEM_MIN_SUCCESS',
    FETCH_STOCK_ITEM_MIN_ERROR:'FETCH_STOCK_ITEM_MIN_ERROR',
}