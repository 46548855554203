import {groupConstants} from "./GroupConstants";

const {
    SAVE_GROUP_ERROR,
    SAVE_GROUP_START,
    SAVE_GROUP_SUCCESS,
    EDIT_GROUP_ERROR,
    EDIT_GROUP_START,
    EDIT_GROUP_SUCCESS,
    PREVIEW_GROUP_ERROR,
    PREVIEW_GROUP_START,
    PREVIEW_GROUP_SUCCESS,
    SEARCH_GROUP_ERROR,
    SEARCH_GROUP_START,
    SEARCH_GROUP_SUCCESS,
    DELETE_GROUP_ERROR,
    DELETE_GROUP_START,
    DELETE_GROUP_SUCCESS
} = groupConstants

export const saveGroupStart = () => ({
    type: SAVE_GROUP_START
})
export const saveGroupSuccess = data => ({
    type: SAVE_GROUP_SUCCESS,
    payload: {
        data: data
    }
})
export const saveGroupError = (errorMessage) => ({
    type: SAVE_GROUP_ERROR,
    payload: {
        data: errorMessage
    }
})
export const searchGroupStart = () => ({
    type: SEARCH_GROUP_START
})
export const searchGroupSuccess = data => ({
    type: SEARCH_GROUP_SUCCESS,
    payload: {
        data: data
    }
})
export const searchGroupError = (errorMessage) => ({
    type: SEARCH_GROUP_ERROR,
    payload: {
        data: errorMessage
    }
})
export const previewGroupStart = () => ({
    type: PREVIEW_GROUP_START
})
export const previewGroupSuccess = data => ({
    type: PREVIEW_GROUP_SUCCESS,
    payload: {
        data: data
    }
})
export const previewGroupError = (errorMessage) => ({
    type: PREVIEW_GROUP_ERROR,
    payload: {
        data: errorMessage
    }
})
export const editGroupStart = () => ({
    type: EDIT_GROUP_START
})
export const editGroupSuccess = data => ({
    type: EDIT_GROUP_SUCCESS,
    payload: {
        data: data
    }
})
export const editGroupError = (errorMessage) => ({
    type: EDIT_GROUP_ERROR,
    payload: {
        data: errorMessage
    }
})

export const deleteGroupStart = () => ({
    type: DELETE_GROUP_START
})
export const deleteGroupSuccess = data => ({
    type: DELETE_GROUP_SUCCESS,
    payload: {
        data: data
    }
})
export const deleteGroupError = (errorMessage) => ({
    type: DELETE_GROUP_ERROR,
    payload: {
        data: errorMessage
    }
})