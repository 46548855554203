import {paymentConstants} from "./PaymentConstants";

const {
    SAVE_PAYMENT_ERROR,
    SAVE_PAYMENT_START,
    SAVE_PAYMENT_SUCCESS,
    EDIT_PAYMENT_ERROR,
    EDIT_PAYMENT_START,
    EDIT_PAYMENT_SUCCESS,
    PREVIEW_PAYMENT_ERROR,
    PREVIEW_PAYMENT_START,
    PREVIEW_PAYMENT_SUCCESS,
    SEARCH_PAYMENT_ERROR,
    SEARCH_PAYMENT_START,
    SEARCH_PAYMENT_SUCCESS,
    DELETE_PAYMENT_ERROR,
    DELETE_PAYMENT_START,
    DELETE_PAYMENT_SUCCESS
} = paymentConstants

export const savePaymentStart = () => ({
    type: SAVE_PAYMENT_START
})
export const savePaymentSuccess = data => ({
    type: SAVE_PAYMENT_SUCCESS,
    payload: {
        data: data
    }
})
export const savePaymentError = (errorMessage) => ({
    type: SAVE_PAYMENT_ERROR,
    payload: {
        data: errorMessage
    }
})
export const searchPaymentStart = () => ({
    type: SEARCH_PAYMENT_START
})
export const searchPaymentSuccess = data => ({
    type: SEARCH_PAYMENT_SUCCESS,
    payload: {
        data: data
    }
})
export const searchPaymentError = (errorMessage) => ({
    type: SEARCH_PAYMENT_ERROR,
    payload: {
        data: errorMessage
    }
})
export const previewPaymentStart = () => ({
    type: PREVIEW_PAYMENT_START
})
export const previewPaymentSuccess = data => ({
    type: PREVIEW_PAYMENT_SUCCESS,
    payload: {
        data: data
    }
})
export const previewPaymentError = (errorMessage) => ({
    type: PREVIEW_PAYMENT_ERROR,
    payload: {
        data: errorMessage
    }
})
export const editPaymentStart = () => ({
    type: EDIT_PAYMENT_START
})
export const editPaymentSuccess = data => ({
    type: EDIT_PAYMENT_SUCCESS,
    payload: {
        data: data
    }
})
export const editPaymentError = (errorMessage) => ({
    type: EDIT_PAYMENT_ERROR,
    payload: {
        data: errorMessage
    }
})

export const deletePaymentStart = () => ({
    type: DELETE_PAYMENT_START
})
export const deletePaymentSuccess = data => ({
    type: DELETE_PAYMENT_SUCCESS,
    payload: {
        data: data
    }
})
export const deletePaymentError = (errorMessage) => ({
    type: DELETE_PAYMENT_ERROR,
    payload: {
        data: errorMessage
    }
})