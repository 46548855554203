import {groupConstants} from '@ams-fe/action-module'

const {
    SAVE_GROUP_ERROR,
    SAVE_GROUP_SUCCESS,
    SAVE_GROUP_START,
    DELETE_GROUP_ERROR,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_START,
    PREVIEW_GROUP_ERROR,
    PREVIEW_GROUP_SUCCESS,
    PREVIEW_GROUP_START,
    EDIT_GROUP_ERROR,
    EDIT_GROUP_SUCCESS,
    EDIT_GROUP_START,
    SEARCH_GROUP_ERROR,
    SEARCH_GROUP_SUCCESS,
    SEARCH_GROUP_START
} = groupConstants

const saveInitialState = {
    isSaveLoading: false,
    groupSaveSuccessMessage: '',
    groupSaveErrorMessage: ''
}
const searchInitialState={
    isSearchLoading:false,
    groupList:[],
    groupSearchErrorMessage:''
}
const previewInitialState={
    isPreviewLoading:false,
    previewGroupData:'',
    previewGroupErrorLoading:''
}
const editInitialState={
    isEditLoading:false,
    editGroupSuccessMessage:'',
    editGroupErrorMessage:''
}
const deleteInitialState={
    isDeleteLoading:false,
    deleteGroupSuccessMessage:'',
    deleteLegerErrorMessage:''
}

export const saveGroupReducer = (state = {saveInitialState}, action) => {
    switch (action.type) {
        case SAVE_GROUP_START:
            return {
                ...state,
                isSaveLoading: true,
                groupSaveErrorMessage: '',
                groupSaveSuccessMessage: ''
            }
        case SAVE_GROUP_SUCCESS:
            return {
                ...state,
                isSaveLoading: false,
                groupSaveSuccessMessage: action.payload.data || 'Group Added Successfully !!!',
                groupSaveErrorMessage: ''
            }
        case SAVE_GROUP_ERROR:
            return {
                ...state,
                isSaveLoading: false,
                groupSaveErrorMessage: action.payload.data,
                groupSaveSuccessMessage: ''
            }
        default:
            return {
                ...state
            }
    }

}
export const editGroupReducer = (state = {editInitialState}, action) => {
    switch (action.type) {
        case EDIT_GROUP_START:
            return {
                ...state,
                isEditLoading: true,
                editGroupSuccessMessage: '',
                editGroupErrorMessage: ''
            }
        case EDIT_GROUP_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                editGroupSuccessMessage: action.payload.data,
                editGroupErrorMessage: ''
            }
        case EDIT_GROUP_ERROR:
            return {
                ...state,
                isEditLoading: false,
                editGroupSuccessMessage: '',
                editGroupErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const searchGroupReducer = (state = {searchInitialState}, action) => {
    switch (action.type) {
        case SEARCH_GROUP_START:
            return {
                ...state,
                isSearchLoading:true,
                groupList:[],
                groupSearchErrorMessage:''
            }
        case SEARCH_GROUP_SUCCESS:
            return {
                ...state,
                isSearchLoading:false,
                groupList:action.payload.data,
                groupSearchErrorMessage:''
            }
        case SEARCH_GROUP_ERROR:
            return {
                ...state,
                isSearchLoading:false,
                groupList:[],
                groupSearchErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const previewGroupReducer = (state = {previewInitialState}, action) => {
    switch (action.type) {
        case PREVIEW_GROUP_START:
            return {
                ...state,
                isPreviewLoading:true,
                previewGroupData:'',
                previewGroupErrorLoading:''
            }
        case PREVIEW_GROUP_SUCCESS:
            return {
                ...state,
                isPreviewLoading:false,
                previewGroupData:action.payload.data,
                previewGroupErrorLoading:''
            }
        case PREVIEW_GROUP_ERROR:
            return {
                ...state,
                isPreviewLoading:false,
                previewGroupData:'',
                previewGroupErrorLoading:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const deleteGroupReducer = (state = {deleteInitialState}, action) => {
    switch (action.type) {
        case DELETE_GROUP_START:
            return {
                ...state,
                isDeleteLoading:true,
                deleteGroupSuccessMessage:'',
                deleteLegerErrorMessage:''
            }
        case DELETE_GROUP_SUCCESS:
            return {
                ...state,
                isDeleteLoading:false,
                deleteGroupSuccessMessage:action.payload.data,
                deleteLegerErrorMessage:''
            }
        case DELETE_GROUP_ERROR:
            return {
                ...state,
                isDeleteLoading:false,
                deleteGroupSuccessMessage:'',
                deleteLegerErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}