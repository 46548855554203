import {underAccountActionConstants} from '@ams-fe/action-module'

const {
    FETCH_UNDER_ERROR,
    FETCH_UNDER_SUCCESS,
    FETCH_UNDER_START
} = underAccountActionConstants

const fetchInitialState={
    isFetchLoading:false,
    underAccountList:[],
    fetchErrorMessage:'',
}

export const UnderAccountFetchReducer=(state={fetchInitialState},action)=>{
    switch (action.type){
        case FETCH_UNDER_START:
            return{
                ...state,
                isFetchLoading: true,
                underAccountList:[],
                fetchErrorMessage: ''
            }
        case FETCH_UNDER_SUCCESS:
            return{
                ...state,
                isFetchLoading: false,
                underAccountList: action.payload.data,
                fetchErrorMessage: ''
            }
        case FETCH_UNDER_ERROR:
            return{
                ...state,
                isFetchLoading: false,
                underAccountList: [],
                fetchErrorMessage: action.payload.data,
            }
        default:
            return {
                ...state
            }
    }

}