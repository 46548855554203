import {underAccountActionConstants} from "./underAccountActionConstants";

const {
    FETCH_UNDER_START,
    FETCH_UNDER_SUCCESS,
    FETCH_UNDER_ERROR
}=underAccountActionConstants

export const fetchUnderStart=()=>({
    type:FETCH_UNDER_START
})

export const fetchUnderSuccess=data=>({
    type:FETCH_UNDER_SUCCESS,
    payload:{
        data
    }
})

export const fetchUnderError=(errorMessage)=>({
    type:FETCH_UNDER_ERROR,
    payload:{
        data:errorMessage
    }
})