import {ledgerConstants} from "./ledgerConstants";

const {
    SAVE_LEDGER_ERROR,
    SAVE_LEDGER_START,
    SAVE_LEDGER_SUCCESS,
    EDIT_LEDGER_ERROR,
    EDIT_LEDGER_START,
    EDIT_LEDGER_SUCCESS,
    PREVIEW_LEDGER_ERROR,
    PREVIEW_LEDGER_START,
    PREVIEW_LEDGER_SUCCESS,
    SEARCH_LEDGER_ERROR,
    SEARCH_LEDGER_START,
    SEARCH_LEDGER_SUCCESS,
    DELETE_LEDGER_ERROR,
    DELETE_LEDGER_START,
    DELETE_LEDGER_SUCCESS,
    FETCH_LEDGER_ERROR,
    FETCH_LEDGER_START,
    FETCH_LEDGER_SUCCESS
} = ledgerConstants

export const saveLedgerStart = () => ({
    type: SAVE_LEDGER_START
})
export const saveLedgerSuccess = data => ({
    type: SAVE_LEDGER_SUCCESS,
    payload: {
        data: data
    }
})
export const saveLedgerError = (errorMessage) => ({
    type: SAVE_LEDGER_ERROR,
    payload: {
        data: errorMessage
    }
})
export const searchLedgerStart = () => ({
    type: SEARCH_LEDGER_START
})
export const searchLedgerSuccess = data => ({
    type: SEARCH_LEDGER_SUCCESS,
    payload: {
        data: data
    }
})
export const searchLedgerError = (errorMessage) => ({
    type: SEARCH_LEDGER_ERROR,
    payload: {
        data: errorMessage
    }
})
export const previewLedgerStart = () => ({
    type: PREVIEW_LEDGER_START
})
export const previewLedgerSuccess = data => ({
    type: PREVIEW_LEDGER_SUCCESS,
    payload: {
        data: data
    }
})
export const previewLedgerError = (errorMessage) => ({
    type: PREVIEW_LEDGER_ERROR,
    payload: {
        data: errorMessage
    }
})
export const editLedgerStart = () => ({
    type: EDIT_LEDGER_START
})
export const editLedgerSuccess = data => ({
    type: EDIT_LEDGER_SUCCESS,
    payload: {
        data: data
    }
})
export const editLedgerError = (errorMessage) => ({
    type: EDIT_LEDGER_ERROR,
    payload: {
        data: errorMessage
    }
})

export const deleteLedgerStart = () => ({
    type: DELETE_LEDGER_START
})
export const deleteLedgerSuccess = data => ({
    type: DELETE_LEDGER_SUCCESS,
    payload: {
        data: data
    }
})
export const deleteLedgerError = (errorMessage) => ({
    type: DELETE_LEDGER_ERROR,
    payload: {
        data: errorMessage
    }
})

export const fetchLedgerStart = () => ({
    type: FETCH_LEDGER_START
})
export const fetchLedgerSuccess = data => ({
    type: FETCH_LEDGER_SUCCESS,
    payload: {
        data: data
    }
})
export const fetchLedgerError = (errorMessage) => ({
    type: FETCH_LEDGER_ERROR,
    payload: {
        data: errorMessage
    }
})