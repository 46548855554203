import {combineReducers} from "redux";
import {
    UnderAccountFetchReducer,
    saveLedgerReducer,
    editLedgerReducer,
    deleteLedgerReducer,
    previewLedgerReducer,
    searchLedgerReducer,
    saveGroupReducer,
    editGroupReducer,
    searchGroupReducer,
    previewGroupReducer,
    deleteGroupReducer,
    saveUomReducer,
    searchUomReducer,
    deleteUomReducer,
    previewUomReducer,
    editUomReducer,
    typeUomReducer,
    saveStockItemReducer,
    searchStockItemReducer,
    searchItemReducer,
    saveItemReducer,
    deleteStockItemReducer,
    deleteItemReducer,
    previewStockItemReducer,
    previewItemReducer,
    editStockItemReducer,
    editItemReducer,
    minItemReducer,
    minUomReducer,
    fetchActiveLedgerReducer,
    saveContraReducer,
    searchContraReducer,
    deleteContraReducer,
    previewContraReducer,
    editContraReducer,
    savePaymentReducer,
    deletePaymentReducer,
    previewPaymentReducer,
    editPaymentReducer,
    searchPaymentReducer,
    minStockItemReducer,
    searchActivityReducer,
    searchReportReducer
} from '../reducer'

const appReducers = combineReducers({
    UnderAccountFetchReducer,
    saveLedgerReducer,
    editLedgerReducer,
    deleteLedgerReducer,
    searchLedgerReducer,
    previewLedgerReducer,
    saveGroupReducer,
    editGroupReducer,
    deleteGroupReducer,
    searchGroupReducer,
    previewGroupReducer,
    searchUomReducer,
    saveUomReducer,
    deleteUomReducer,
    editUomReducer,
    previewUomReducer,
    typeUomReducer,
    saveStockItemReducer,
    searchStockItemReducer,
    searchItemReducer,
    saveItemReducer,
    deleteStockItemReducer,
    deleteItemReducer,
    previewStockItemReducer,
    previewItemReducer,
    editStockItemReducer,
    editItemReducer,
    minItemReducer,
    minUomReducer,
    saveContraReducer,
    searchContraReducer,
    editContraReducer,
    deleteContraReducer,
    previewContraReducer,
    fetchActiveLedgerReducer,
    searchPaymentReducer,
    editPaymentReducer,
    deletePaymentReducer,
    previewPaymentReducer,
    savePaymentReducer,
    minStockItemReducer,
    searchActivityReducer,
    searchReportReducer

})

export const rootReducers = (state, action) => {
    if (action.type === 'LOCATION_CHANGE') {
        state = {}
    }
    return appReducers(state, action)
}