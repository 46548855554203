import {stockItemConstants} from '@ams-fe/action-module'

const {
    SAVE_ITEM_ERROR,
    SAVE_ITEM_START,
    SAVE_ITEM_SUCCESS,
    EDIT_ITEM_ERROR,
    EDIT_ITEM_START,
    EDIT_ITEM_SUCCESS,
    PREVIEW_ITEM_ERROR,
    PREVIEW_ITEM_START,
    PREVIEW_ITEM_SUCCESS,
    SEARCH_ITEM_ERROR,
    SEARCH_ITEM_START,
    SEARCH_ITEM_SUCCESS,
    DELETE_ITEM_ERROR,
    DELETE_ITEM_START,
    DELETE_ITEM_SUCCESS,
    FETCH_ITEM_MIN_ERROR,
    FETCH_ITEM_MIN_SUCCESS,
    FETCH_ITEM_MIN_START,

    SAVE_STOCK_ITEM_ERROR,
    SAVE_STOCK_ITEM_START,
    SAVE_STOCK_ITEM_SUCCESS,
    EDIT_STOCK_ITEM_ERROR,
    EDIT_STOCK_ITEM_START,
    EDIT_STOCK_ITEM_SUCCESS,
    PREVIEW_STOCK_ITEM_ERROR,
    PREVIEW_STOCK_ITEM_START,
    PREVIEW_STOCK_ITEM_SUCCESS,
    SEARCH_STOCK_ITEM_ERROR,
    SEARCH_STOCK_ITEM_START,
    SEARCH_STOCK_ITEM_SUCCESS,
    DELETE_STOCK_ITEM_ERROR,
    DELETE_STOCK_ITEM_START,
    DELETE_STOCK_ITEM_SUCCESS,
    FETCH_STOCK_ITEM_MIN_ERROR,
    FETCH_STOCK_ITEM_MIN_START,
    FETCH_STOCK_ITEM_MIN_SUCCESS
} = stockItemConstants


const saveItemInitialState = {
    isSaveLoading: false,
    itemSaveSuccessMessage: '',
    itemSaveErrorMessage: ''
}
const searchItemInitialState = {
    isSearchLoading: false,
    itemList: [],
    itemSearchErrorMessage: ''
}
const previewItemInitialState = {
    isPreviewLoading: false,
    previewItemData: '',
    previewItemErrorLoading: ''
}
const editItemInitialState = {
    isEditLoading: false,
    editItemSuccessMessage: '',
    editItemErrorMessage: ''
}
const deleteItemInitialState = {
    isDeleteLoading: false,
    deleteItemSuccessMessage: '',
    deleteLegerErrorMessage: ''
}

const saveStockItemInitialState = {
    isSaveLoading: false,
    stockItemSaveSuccessMessage: '',
    stockItemSaveErrorMessage: ''
}
const searchStockItemInitialState = {
    isSearchLoading: false,
    stockItemList: [],
    stockItemSearchErrorMessage: ''
}
const previewStockItemInitialState = {
    isPreviewLoading: false,
    previewItemData: '',
    previewItemErrorLoading: ''
}
const editStockItemInitialState = {
    isEditLoading: false,
    editStockItemSuccessMessage: '',
    editStockItemErrorMessage: ''
}
const deleteStockItemInitialState = {
    isDeleteLoading: false,
    deleteStockItemSuccessMessage: '',
    deleteStockItemErrorMessage: ''
}

const itemActiveMinInitialState = {
    isMinLoading: true,
    minList: [],
    minErrorMessage: ''
}

const stockItemActiveMinInitialState = {
    isMinLoading: true,
    minList: [],
    minErrorMessage: ''
}

export const saveItemReducer = (state = {saveItemInitialState}, action) => {
    switch (action.type) {
        case SAVE_ITEM_START:
            return {
                ...state,
                isSaveLoading: true,
                itemSaveErrorMessage: '',
                itemSaveSuccessMessage: ''
            }
        case SAVE_ITEM_SUCCESS:
            return {
                ...state,
                isSaveLoading: false,
                itemSaveSuccessMessage: action.payload.data || 'Item Added Successfully !!!',
                itemSaveErrorMessage: ''
            }
        case SAVE_ITEM_ERROR:
            return {
                ...state,
                isSaveLoading: false,
                itemSaveErrorMessage: action.payload.data,
                itemSaveSuccessMessage: ''
            }
        default:
            return {
                ...state
            }
    }

}
export const editItemReducer = (state = {editItemInitialState}, action) => {
    switch (action.type) {
        case EDIT_ITEM_START:
            return {
                ...state,
                isEditLoading: true,
                editItemSuccessMessage: '',
                editItemErrorMessage: ''
            }
        case EDIT_ITEM_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                editItemSuccessMessage: action.payload.data,
                editItemErrorMessage: ''
            }
        case EDIT_ITEM_ERROR:
            return {
                ...state,
                isEditLoading: false,
                editItemSuccessMessage: '',
                editItemErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const searchItemReducer = (state = {searchItemInitialState}, action) => {
    switch (action.type) {
        case SEARCH_ITEM_START:
            return {
                ...state,
                isSearchLoading: true,
                itemList: [],
                itemSearchErrorMessage: ''
            }
        case SEARCH_ITEM_SUCCESS:
            return {
                ...state,
                isSearchLoading: false,
                itemList: action.payload.data,
                itemSearchErrorMessage: ''
            }
        case SEARCH_ITEM_ERROR:
            return {
                ...state,
                isSearchLoading: false,
                itemList: [],
                itemSearchErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const previewItemReducer = (state = {previewItemInitialState}, action) => {
    switch (action.type) {
        case PREVIEW_ITEM_START:
            return {
                ...state,
                isPreviewLoading: true,
                previewItemData: '',
                previewItemErrorLoading: ''
            }
        case PREVIEW_ITEM_SUCCESS:
            return {
                ...state,
                isPreviewLoading: false,
                previewItemData: action.payload.data,
                previewItemErrorLoading: ''
            }
        case PREVIEW_ITEM_ERROR:
            return {
                ...state,
                isPreviewLoading: false,
                previewItemData: '',
                previewItemErrorLoading: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const deleteItemReducer = (state = {deleteItemInitialState}, action) => {
    switch (action.type) {
        case DELETE_ITEM_START:
            return {
                ...state,
                isDeleteLoading: true,
                deleteItemSuccessMessage: '',
                deleteLegerErrorMessage: ''
            }
        case DELETE_ITEM_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                deleteItemSuccessMessage: action.payload.data,
                deleteLegerErrorMessage: ''
            }
        case DELETE_ITEM_ERROR:
            return {
                ...state,
                isDeleteLoading: false,
                deleteItemSuccessMessage: '',
                deleteLegerErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}

export const minItemReducer = (state = {itemActiveMinInitialState}, action) => {
    switch (action.type) {
        case FETCH_ITEM_MIN_START:
            return {
                ...state,
                isMinLoading: true,
                minList: [],
                minErrorMessage: ''
            }
        case FETCH_ITEM_MIN_SUCCESS:
            return {
                ...state,
                isMinLoading: false,
                minList: action.payload.data,
                minErrorMessage: ''
            }
        case FETCH_ITEM_MIN_ERROR:
            return {
                ...state,
                isMinLoading: false,
                minList: [],
                minErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}


export const saveStockItemReducer = (state = {saveStockItemInitialState}, action) => {
    switch (action.type) {
        case SAVE_STOCK_ITEM_START:
            return {
                ...state,
                isSaveLoading: true,
                stockItemSaveErrorMessage: '',
                stockItemSaveSuccessMessage: ''
            }
        case SAVE_STOCK_ITEM_SUCCESS:
            return {
                ...state,
                isSaveLoading: false,
                stockItemSaveSuccessMessage: action.payload.data || 'Item Added Successfully !!!',
                stockItemSaveErrorMessage: ''
            }
        case SAVE_STOCK_ITEM_ERROR:
            return {
                ...state,
                isSaveLoading: false,
                stockItemSaveErrorMessage: action.payload.data,
                stockItemSaveSuccessMessage: ''
            }
        default:
            return {
                ...state
            }
    }

}
export const editStockItemReducer = (state = {editStockItemInitialState}, action) => {
    switch (action.type) {
        case EDIT_STOCK_ITEM_START:
            return {
                ...state,
                isEditLoading: true,
                editStockItemSuccessMessage: '',
                editStockItemErrorMessage: ''
            }
        case EDIT_STOCK_ITEM_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                editStockItemSuccessMessage: action.payload.data,
                editStockItemErrorMessage: ''
            }
        case EDIT_STOCK_ITEM_ERROR:
            return {
                ...state,
                isEditLoading: false,
                editStockItemSuccessMessage: '',
                editStockItemErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const searchStockItemReducer = (state = {searchStockItemInitialState}, action) => {
    switch (action.type) {
        case SEARCH_STOCK_ITEM_START:
            return {
                ...state,
                isSearchLoading: true,
                stockItemList: [],
                stockItemSearchErrorMessage: ''
            }
        case SEARCH_STOCK_ITEM_SUCCESS:
            return {
                ...state,
                isSearchLoading: false,
                stockItemList: action.payload.data,
                stockItemSearchErrorMessage: ''
            }
        case SEARCH_STOCK_ITEM_ERROR:
            return {
                ...state,
                isSearchLoading: false,
                stockItemList: [],
                stockItemSearchErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const previewStockItemReducer = (state = {previewStockItemInitialState}, action) => {
    switch (action.type) {
        case PREVIEW_STOCK_ITEM_START:
            return {
                ...state,
                isPreviewLoading: true,
                previewItemData: '',
                previewItemErrorLoading: ''
            }
        case PREVIEW_STOCK_ITEM_SUCCESS:
            return {
                ...state,
                isPreviewLoading: false,
                previewItemData: action.payload.data,
                previewItemErrorLoading: ''
            }
        case PREVIEW_STOCK_ITEM_ERROR:
            return {
                ...state,
                isPreviewLoading: false,
                previewItemData: '',
                previewItemErrorLoading: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const deleteStockItemReducer = (state = {deleteStockItemInitialState}, action) => {
    switch (action.type) {
        case DELETE_STOCK_ITEM_START:
            return {
                ...state,
                isDeleteLoading: true,
                deleteItemSuccessMessage: '',
                deleteLegerErrorMessage: ''
            }
        case DELETE_STOCK_ITEM_SUCCESS:
            return {
                ...state,
                isDeleteLoading: false,
                deleteItemSuccessMessage: action.payload.data,
                deleteLegerErrorMessage: ''
            }
        case DELETE_STOCK_ITEM_ERROR:
            return {
                ...state,
                isDeleteLoading: false,
                deleteItemSuccessMessage: '',
                deleteLegerErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}

export const minStockItemReducer = (state = {stockItemActiveMinInitialState}, action) => {
    switch (action.type) {
        case FETCH_STOCK_ITEM_MIN_START:
            return {
                ...state,
                isMinLoading: true,
                minList: [],
                minErrorMessage: ''
            }
        case FETCH_STOCK_ITEM_MIN_SUCCESS:
            return {
                ...state,
                isMinLoading: false,
                minList: action.payload.data,
                minErrorMessage: ''
            }
        case FETCH_STOCK_ITEM_MIN_ERROR:
            return {
                ...state,
                isMinLoading: false,
                minList: [],
                minErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}