import {activityConstants} from "./ActivityConstants";

const {
    SAVE_CONTRA_ERROR,
    SAVE_CONTRA_START,
    SAVE_CONTRA_SUCCESS,
    EDIT_CONTRA_ERROR,
    EDIT_CONTRA_START,
    EDIT_CONTRA_SUCCESS,
    PREVIEW_CONTRA_ERROR,
    PREVIEW_CONTRA_START,
    PREVIEW_CONTRA_SUCCESS,
    SEARCH_ACTIVITY_ERROR,
    SEARCH_ACTIVITY_START,
    SEARCH_ACTIVITY_SUCCESS,
    DELETE_CONTRA_ERROR,
    DELETE_CONTRA_START,
    DELETE_CONTRA_SUCCESS
} = activityConstants

export const saveContraStart = () => ({
    type: SAVE_CONTRA_START
})
export const saveContraSuccess = data => ({
    type: SAVE_CONTRA_SUCCESS,
    payload: {
        data: data
    }
})
export const saveContraError = (errorMessage) => ({
    type: SAVE_CONTRA_ERROR,
    payload: {
        data: errorMessage
    }
})
export const searchActivityStart = () => ({
    type: SEARCH_ACTIVITY_START
})
export const searchActivitySuccess = data => ({
    type: SEARCH_ACTIVITY_SUCCESS,
    payload: {
        data: data
    }
})
export const searchActivityError = (errorMessage) => ({
    type: SEARCH_ACTIVITY_ERROR,
    payload: {
        data: errorMessage
    }
})
export const previewContraStart = () => ({
    type: PREVIEW_CONTRA_START
})
export const previewContraSuccess = data => ({
    type: PREVIEW_CONTRA_SUCCESS,
    payload: {
        data: data
    }
})
export const previewContraError = (errorMessage) => ({
    type: PREVIEW_CONTRA_ERROR,
    payload: {
        data: errorMessage
    }
})
export const editContraStart = () => ({
    type: EDIT_CONTRA_START
})
export const editContraSuccess = data => ({
    type: EDIT_CONTRA_SUCCESS,
    payload: {
        data: data
    }
})
export const editContraError = (errorMessage) => ({
    type: EDIT_CONTRA_ERROR,
    payload: {
        data: errorMessage
    }
})

export const deleteContraStart = () => ({
    type: DELETE_CONTRA_START
})
export const deleteContraSuccess = data => ({
    type: DELETE_CONTRA_SUCCESS,
    payload: {
        data: data
    }
})
export const deleteContraError = (errorMessage) => ({
    type: DELETE_CONTRA_ERROR,
    payload: {
        data: errorMessage
    }
})