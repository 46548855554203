import {ledgerConstants} from '@ams-fe/action-module'

const {
    SAVE_LEDGER_ERROR,
    SAVE_LEDGER_SUCCESS,
    SAVE_LEDGER_START,
    DELETE_LEDGER_ERROR,
    DELETE_LEDGER_SUCCESS,
    DELETE_LEDGER_START,
    PREVIEW_LEDGER_ERROR,
    PREVIEW_LEDGER_SUCCESS,
    PREVIEW_LEDGER_START,
    EDIT_LEDGER_ERROR,
    EDIT_LEDGER_SUCCESS,
    EDIT_LEDGER_START,
    SEARCH_LEDGER_ERROR,
    SEARCH_LEDGER_SUCCESS,
    SEARCH_LEDGER_START,
    FETCH_LEDGER_SUCCESS,
    FETCH_LEDGER_ERROR,
    FETCH_LEDGER_START
} = ledgerConstants

const saveInitialState = {
    isSaveLoading: false,
    ledgerSaveSuccessMessage: '',
    ledgerSaveErrorMessage: ''
}
const searchInitialState={
    isSearchLoading:false,
    ledgerList:[],
    ledgerSearchErrorMessage:''
}
const previewInitialState={
    isPreviewLoading:false,
    previewLedgerData:'',
    previewLedgerErrorLoading:''
}
const editInitialState={
    isEditLoading:false,
    editLedgerSuccessMessage:'',
    editLedgerErrorMessage:''
}
const deleteInitialState={
    isDeleteLoading:false,
    deleteLedgerSuccessMessage:'',
    deleteLegerErrorMessage:''
}

const ledgerMinInitialState={
    isFetchLoading: false,
    fetchList:[],
    fetchErrorMessage: ''
}

export const saveLedgerReducer = (state = {saveInitialState}, action) => {
    switch (action.type) {
        case SAVE_LEDGER_START:
            return {
                ...state,
                isSaveLoading: true,
                ledgerSaveErrorMessage: '',
                ledgerSaveSuccessMessage: ''
            }
        case SAVE_LEDGER_SUCCESS:
            return {
                ...state,
                isSaveLoading: false,
                ledgerSaveSuccessMessage: action.payload.data || 'Ledger Added Successfully !!!',
                ledgerSaveErrorMessage: ''
            }
        case SAVE_LEDGER_ERROR:
            return {
                ...state,
                isSaveLoading: false,
                ledgerSaveErrorMessage: action.payload.data,
                ledgerSaveSuccessMessage: ''
            }
        default:
            return {
                ...state
            }
    }

}
export const editLedgerReducer = (state = {editInitialState}, action) => {
    switch (action.type) {
        case EDIT_LEDGER_START:
            return {
                ...state,
                isEditLoading: true,
                editLedgerSuccessMessage: '',
                editLedgerErrorMessage: ''
            }
        case EDIT_LEDGER_SUCCESS:
            return {
                ...state,
                isEditLoading: false,
                editLedgerSuccessMessage: action.payload.data,
                editLedgerErrorMessage: ''
            }
        case EDIT_LEDGER_ERROR:
            return {
                ...state,
                isEditLoading: false,
                editLedgerSuccessMessage: '',
                editLedgerErrorMessage: action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const searchLedgerReducer = (state = {searchInitialState}, action) => {
    switch (action.type) {
        case SEARCH_LEDGER_START:
            return {
                ...state,
                isSearchLoading:true,
                ledgerList:[],
                ledgerSearchErrorMessage:''
            }
        case SEARCH_LEDGER_SUCCESS:
            return {
                ...state,
                isSearchLoading:false,
                ledgerList:action.payload.data,
                ledgerSearchErrorMessage:''
            }
        case SEARCH_LEDGER_ERROR:
            return {
                ...state,
                isSearchLoading:false,
                ledgerList:[],
                ledgerSearchErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const previewLedgerReducer = (state = {previewInitialState}, action) => {
    switch (action.type) {
        case PREVIEW_LEDGER_START:
            return {
                ...state,
                isPreviewLoading:true,
                previewLedgerData:'',
                previewLedgerErrorLoading:''
            }
        case PREVIEW_LEDGER_SUCCESS:
            return {
                ...state,
                isPreviewLoading:false,
                previewLedgerData:action.payload.data,
                previewLedgerErrorLoading:''
            }
        case PREVIEW_LEDGER_ERROR:
            return {
                ...state,
                isPreviewLoading:false,
                previewLedgerData:'',
                previewLedgerErrorLoading:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
export const deleteLedgerReducer = (state = {deleteInitialState}, action) => {
    switch (action.type) {
        case DELETE_LEDGER_START:
            return {
                ...state,
                isDeleteLoading:true,
                deleteLedgerSuccessMessage:'',
                deleteLegerErrorMessage:''
            }
        case DELETE_LEDGER_SUCCESS:
            return {
                ...state,
                isDeleteLoading:false,
                deleteLedgerSuccessMessage:action.payload.data,
                deleteLegerErrorMessage:''
            }
        case DELETE_LEDGER_ERROR:
            return {
                ...state,
                isDeleteLoading:false,
                deleteLedgerSuccessMessage:'',
                deleteLegerErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}

export const fetchActiveLedgerReducer = (state = {ledgerMinInitialState}, action) => {
    switch (action.type) {
        case FETCH_LEDGER_START:
            return {
                ...state,
                isFetchLoading:true,
                fetchList:[],
                fetchErrorMessage:''
            }
        case FETCH_LEDGER_SUCCESS:
            return {
                ...state,
                isFetchLoading:false,
                fetchList:action.payload.data,
                fetchErrorMessage:''
            }
        case FETCH_LEDGER_ERROR:
            return {
                ...state,
                isFetchLoading:false,
                fetchList:[],
                fetchErrorMessage:action.payload.data
            }
        default:
            return {
                ...state
            }
    }

}
