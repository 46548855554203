export const ledgerConstants = {
    SAVE_LEDGER_START: 'SAVE_LEDGER_START',
    SAVE_LEDGER_SUCCESS: 'SAVE_LEDGER_SUCCESS',
    SAVE_LEDGER_ERROR: 'SAVE_LEDGER_ERROR',
    SEARCH_LEDGER_START: 'SEARCH_LEDGER_START',
    SEARCH_LEDGER_SUCCESS: 'SEARCH_LEDGER_SUCCESS',
    SEARCH_LEDGER_ERROR: 'SEARCH_LEDGER_ERROR',
    EDIT_LEDGER_START: 'EDIT_LEDGER_START',
    EDIT_LEDGER_SUCCESS: 'EDIT_LEDGER_SUCCESS',
    EDIT_LEDGER_ERROR: 'EDIT_LEDGER_ERROR',
    PREVIEW_LEDGER_START: 'PREVIEW_LEDGER_START',
    PREVIEW_LEDGER_SUCCESS: 'PREVIEW_LEDGER_SUCCESS',
    PREVIEW_LEDGER_ERROR: 'PREVIEW_LEDGER_ERROR',
    DELETE_LEDGER_START:'DELETE_LEDGER_START',
    DELETE_LEDGER_SUCCESS:'DELETE_LEDGER_SUCCESS',
    DELETE_LEDGER_ERROR:'DELETE_LEDGER_ERROR',
    FETCH_LEDGER_START:'FETCH_LEDGER_ERROR',
    FETCH_LEDGER_SUCCESS:'FETCH_LEDGER_SUCCESS',
    FETCH_LEDGER_ERROR:'FETCH_LEDGER_SUCCESS'
}