export const groupConstants = {
    SAVE_GROUP_START: 'SAVE_GROUP_START',
    SAVE_GROUP_SUCCESS: 'SAVE_GROUP_SUCCESS',
    SAVE_GROUP_ERROR: 'SAVE_GROUP_ERROR',
    SEARCH_GROUP_START: 'SEARCH_GROUP_START',
    SEARCH_GROUP_SUCCESS: 'SEARCH_GROUP_SUCCESS',
    SEARCH_GROUP_ERROR: 'SEARCH_GROUP_ERROR',
    EDIT_GROUP_START: 'EDIT_GROUP_START',
    EDIT_GROUP_SUCCESS: 'EDIT_GROUP_SUCCESS',
    EDIT_GROUP_ERROR: 'EDIT_GROUP_ERROR',
    PREVIEW_GROUP_START: 'PREVIEW_GROUP_START',
    PREVIEW_GROUP_SUCCESS: 'PREVIEW_GROUP_SUCCESS',
    PREVIEW_GROUP_ERROR: 'PREVIEW_GROUP_ERROR',
    DELETE_GROUP_START:'DELETE_GROUP_START',
    DELETE_GROUP_SUCCESS:'DELETE_GROUP_SUCCESS',
    DELETE_GROUP_ERROR:'DELETE_GROUP_ERROR'
}