export const paymentConstants = {
    SAVE_PAYMENT_START: 'SAVE_PAYMENT_START',
    SAVE_PAYMENT_SUCCESS: 'SAVE_PAYMENT_SUCCESS',
    SAVE_PAYMENT_ERROR: 'SAVE_PAYMENT_ERROR',
    SEARCH_PAYMENT_START: 'SEARCH_PAYMENT_START',
    SEARCH_PAYMENT_SUCCESS: 'SEARCH_PAYMENT_SUCCESS',
    SEARCH_PAYMENT_ERROR: 'SEARCH_PAYMENT_ERROR',
    EDIT_PAYMENT_START: 'EDIT_PAYMENT_START',
    EDIT_PAYMENT_SUCCESS: 'EDIT_PAYMENT_SUCCESS',
    EDIT_PAYMENT_ERROR: 'EDIT_PAYMENT_ERROR',
    PREVIEW_PAYMENT_START: 'PREVIEW_PAYMENT_START',
    PREVIEW_PAYMENT_SUCCESS: 'PREVIEW_PAYMENT_SUCCESS',
    PREVIEW_PAYMENT_ERROR: 'PREVIEW_PAYMENT_ERROR',
    DELETE_PAYMENT_START:'DELETE_PAYMENT_START',
    DELETE_PAYMENT_SUCCESS:'DELETE_PAYMENT_SUCCESS',
    DELETE_PAYMENT_ERROR:'DELETE_PAYMENT_ERROR'
}