export const unitOfMeasurementConstants = {
    SAVE_UOM_START: 'SAVE_UOM_START',
    SAVE_UOM_SUCCESS: 'SAVE_UOM_SUCCESS',
    SAVE_UOM_ERROR: 'SAVE_UOM_ERROR',
    SEARCH_UOM_START: 'SEARCH_UOM_START',
    SEARCH_UOM_SUCCESS: 'SEARCH_UOM_SUCCESS',
    SEARCH_UOM_ERROR: 'SEARCH_UOM_ERROR',
    EDIT_UOM_START: 'EDIT_UOM_START',
    EDIT_UOM_SUCCESS: 'EDIT_UOM_SUCCESS',
    EDIT_UOM_ERROR: 'EDIT_UOM_ERROR',
    PREVIEW_UOM_START: 'PREVIEW_UOM_START',
    PREVIEW_UOM_SUCCESS: 'PREVIEW_UOM_SUCCESS',
    PREVIEW_UOM_ERROR: 'PREVIEW_UOM_ERROR',
    DELETE_UOM_START:'DELETE_UOM_START',
    DELETE_UOM_SUCCESS:'DELETE_UOM_SUCCESS',
    DELETE_UOM_ERROR:'DELETE_UOM_ERROR',
    FETCH_UOM_TYPE_START:'FETCH_UOM_TYPE_START',
    FETCH_UOM_TYPE_SUCCESS:'FETCH_UOM_TYPE_SUCCESS',
    FETCH_UOM_TYPE_ERROR:'FETCH_UOM_TYPE_SUCCESS',
    FETCH_UOM_MIN_START:'FETCH_UOM_MIN_START',
    FETCH_UOM_MIN_SUCCESS:'FETCH_UOM_MIN_SUCCESS',
    FETCH_UOM_MIN_ERROR:'FETCH_UOM_MIN_SUCCESS'
}