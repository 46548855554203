export const contraConstants = {
    SAVE_CONTRA_START: 'SAVE_CONTRA_START',
    SAVE_CONTRA_SUCCESS: 'SAVE_CONTRA_SUCCESS',
    SAVE_CONTRA_ERROR: 'SAVE_CONTRA_ERROR',
    SEARCH_CONTRA_START: 'SEARCH_CONTRA_START',
    SEARCH_CONTRA_SUCCESS: 'SEARCH_CONTRA_SUCCESS',
    SEARCH_CONTRA_ERROR: 'SEARCH_CONTRA_ERROR',
    EDIT_CONTRA_START: 'EDIT_CONTRA_START',
    EDIT_CONTRA_SUCCESS: 'EDIT_CONTRA_SUCCESS',
    EDIT_CONTRA_ERROR: 'EDIT_CONTRA_ERROR',
    PREVIEW_CONTRA_START: 'PREVIEW_CONTRA_START',
    PREVIEW_CONTRA_SUCCESS: 'PREVIEW_CONTRA_SUCCESS',
    PREVIEW_CONTRA_ERROR: 'PREVIEW_CONTRA_ERROR',
    DELETE_CONTRA_START:'DELETE_CONTRA_START',
    DELETE_CONTRA_SUCCESS:'DELETE_CONTRA_SUCCESS',
    DELETE_CONTRA_ERROR:'DELETE_CONTRA_ERROR'
}