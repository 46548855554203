import React, {Component} from 'react';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import history from "./history";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css';
import 'material-icons/iconfont/material-icons.scss';
import '@ams-fe/common-sass';
import "nepali-datepicker-reactjs/dist/index.css"
import axios from "axios";
// import './scss/style.scss';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./container/TheLayout'));
const AdminLogin = React.lazy(() => import('./container/Login/AdminLogin'));
const ForgotPassword=React.lazy(()=>import('./container/ForgotPassword/ForgotPassword'))
const ResetPassword=React.lazy(()=>import('./container/ResetPassword/ResetPassword'))

class App extends Component {

    async componentDidMount() {
        const res = await axios.get('https://geolocation-db.com/json/')
        await localStorage.setItem('ipV4',res.data.IPv4)
    }


    render() {
        return (
            <HashRouter history={history}>
                <React.Suspense fallback={loading}>
                    <Switch>

                        <Route exact path="/login" name="Login Page" render={props => <AdminLogin {...props}/>}/>
                        <Route exact path="/forgotPassword" name="Forgot Password" render={props => <ForgotPassword {...props}/>}/>
                        <Route exact path="/reset-password" name="Reset Password" render={props => <ResetPassword {...props}/>}/>
                        {/*<Route exact path="/" name="Admin" render={props => <TheLayout {...props}/>}/>*/}
                        <Route path="/" name="Client" render={props =>
                            (localStorage.getItem("user") != null) ?
                                <TheLayout {...props}/> :
                                <Redirect from="/dashboard" to="/login"/>
                        }/>

                    </Switch>
                </React.Suspense>
            </HashRouter>
        );
    }
}

export default App;
